body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  background-color: #f9f7fa;
}

#root {
  padding-top: 3rem;
}

.headerSectionsOpts {
  padding-top: 20px;
  width: 100%;
  font-size: 2vw;
}

.headerSectionsOpts a {
  padding: 10px;
  text-align: right;
}

.headerNavOpts a {
  padding: 10px;
  height: 100%;
  background-color: #00a1f2;
}

.portfolio-item {
  margin-bottom: 30px;
}

.navbar {
  height: 2.5rem;
}

.navbar {
  background-color: #003466 !important;
  padding: 5px;
  display: block;
}

.navbar-menu {
  background: #3b3b3b;
  height: 2.5rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-menu .btn-group {
  height: 100%;
}

.navbar-menu button {
  background-color: initial;
  font-size: 0.8rem;
  border-style: none;
  border-radius: 0;
}

.navbar-menu label {
  -webkit-transform: scaleY(0.9);
  transform: scaleY(0.9);
  font-weight: 540;
  letter-spacing: 1px;
}

.navbar-toggler {
  display: none;
}

.navbar-nav {
  display: none;
}

.pagination {
  margin-bottom: 30px;
  margin-top: 30px;
}

.navbar-brand {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

.navbar-brand img {
  max-width: 150px;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.genFooter {
  background-color: #003466 !important;
  padding-top: 2rem !important;
}

footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #efefef;
  text-align: center;
}

.sweet-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 25px;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.buttonsNav {
  padding-bottom: 10px;
  width: 100%;
}

.buttonsNav button {
  width: 50%;
}

.buttonsNav .btn-secondary {
  color: #fff;
  background-color: #05427a;
  border-color: #000000;
}

.buttonsNav .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #7ea3c7;
  border-color: #000000;
}

.buttonsNav .gendSelect {
  color: #fff;
  background-color: #7ea3c7;
  border-color: #000000;
}

h1 {
  font-size: 1.5rem;
}

h4 {
  font-size: 0.9rem;
  text-align: center;
}

.footerIcons {
  padding-bottom: 10px;
}

.footerIcons img {
  padding-left: 20px;
}

.footerTxtLinks label {
  padding-left: 10px;
  color: white;
}
.footerTxtLinks label:hover {
  color: #8fb9e6;
  cursor: pointer;
}

.card-title-ranking a {
  color: #212121 !important;
  font-size: 2vmax;
  text-align: left;
  margin: auto;
}

.card-title {
  color: #212121 !important;
  text-align: center;
  font-size: 2vw;
}

.card {
  border: none !important;
}

.card-body {
  /*margin: 2vw;
  padding: 2vw;*/
  padding: 0px !important;
  align-items: center;
  /*border-color: rgb(228, 229, 230);*/
  background-color: transparent;
}

.card-rank-body {
  margin: 0px;
}

.card-title {
  margin: auto;
  text-align: left;
  color: #030102;
  padding-top: 1em;
  font-family: europa, "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial,
    sans-serif;
  text-align: center;
}

@media screen and (min-width: 601px) {
  .card-title {
    font-size: 2vw;
    min-height: 5vh;
  }
}

@media screen and (max-width: 600px) {
  .card-title {
    font-size: 0.7rem;
    min-height: 5vh;
  }
}

.content-list {
  margin: 0px;
  padding: 0px;
}

.product-row {
  margin-left: -20px;
}
.carousel {
  height: 300px;
}

.page-item button {
  color: #686c6f;
}

.page-item.active .page-link {
  background-color: #00a1f2;
}

.rankingIcoLabel {
  color: white;
  width: 100%;
  text-align: center;
  font-size: 0.5rem;
  transform: translate(0px, -0.3rem);
}

.itemGridView {
  margin-top: 2rem;
}

.itemFirstRankView {
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 1rem;
}

.rankingIconCol {
  margin: 0px;
  padding: 0px;
}

.itemFirstRankView .rankingIconCont {
  width: 60%;
  margin: auto;
}

.itemFirstRankView .rankingTitleCont {
  text-align: center;
  width: 100%;
  letter-spacing: 0.1rem;
}

.rankingIconCont {
  width: 40%;
  text-align: center;
}

.rankingIcon {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0;
  padding: 50% 0;
  color: black;
  font-family: Helvetica, Arial Black, sans;
  text-align: center;
  background-size: 100%, 100%;
  background-repeat: no-repeat;
}

.itemFirstRankView .rankingIcon {
  padding: 40% 0;
}

.rankingTitleCont {
  width: 60%;
  text-align: left;
  line-height: normal;
  position: relative;
}

.rankingTitleCont label {
  margin: 0px;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.itemFirstRankView .rankingTitleCont label {
  display: inline-block;
}

.itemFirstRankView .rankingIconCont label {
  transform: translate(0px, 0px);
}

@media screen and (min-width: 601px) {
  .itemFirstRankView .rankingTitleCont label {
    font-size: 4vw;
  }
  .itemFirstRankView .rankingIcoLabel {
    font-size: 4vw;
  }
  .rankingIcoLabel {
    font-size: 1rem;
  }
  .rankingTitleCont label {
    font-size: 1.8vw;
  }
}
@media screen and (max-width: 600px) {
  .itemFirstRankView .rankingTitleCont label {
    font-size: 5vw;
  }
  .itemFirstRankView .rankingIcoLabel {
    font-size: 6vw;
  }
  .rankingIcoLabel {
    font-size: 0.5rem;
  }
  .rankingTitleCont label {
    font-size: 2.6vw;
  }
}

@media screen and (max-width: 360px) {
  .itemFirstRankView .rankingIcoLabel {
    font-size: 6vw;
  }
  .rankingIcoLabel {
    font-size: 0.5;
  }
  .rankingTitleCont label {
    font-size: 2.5vw;
  }
}

.header-sub-menu {
  width: 100%;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.headerMenu a {
  cursor: pointer !important;
}

.headerMenu a:hover {
  color: lightblue !important;
}

.header-subMenu-sep {
  background-color: #05427a;
  width: 100%;
  height: 1vw;
}

.headerMenu {
  color: #63676d;
  letter-spacing: 0.1rem;
  max-height: 10%;
}

.rankingItemRow {
  margin: auto;
}

@media screen and (min-width: 890px) {
  .rankingItemRow {
    max-width: 60vw;
  }
}

.navbar .snIcon {
  height: 100%;
  float: right;
  margin-right: 10px;
}

.navBtnSelected {
  background-color: #00a1f4 !important;
}

.catalog {
  padding-top: 20px !important;
  padding-left: 10px;
  padding-right: 10px;
}
